import React from 'react'
import { ICMChannel } from '@typesApp/cmsPlacement/CMChannel'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import useMediaByDeviceType from '../../../../hooks/useMediaByDeviceType'
import CMSCommonMedia from '@components/Cms/CmsComponents/CmsCommonMedia'
import {
  ContentElementQueryList,
  LinkElementQueryList,
  Title,
  Text,
  WrapperElementQueryList,
} from './ElementQueryList.style'
import HTMLReactParser from 'html-react-parser'

const ElementQueryList: React.FC<{ item: ICMChannel }> = ({ item }) => {
  const { basePath } = useStoreIdentity()

  if (!item) return null
  const title = item.title || ''
  const text = item.teaserText?.replace(/<.*?>/gi, '') || ''
  const media = useMediaByDeviceType(item?.media!)
  const formattedUrl = item.formattedUrl || ''
  const link = `${basePath}${formattedUrl}`

  return (
    <WrapperElementQueryList>
      <LinkElementQueryList data-element-id={`X_X_${item.id}`} href={link}>
        <CMSCommonMedia type={'G1_W_3_ITEMS'} media={media} />
        <ContentElementQueryList>
          <Title
            fontSize={14}
            fontWeight={'bold'}
            isUppercased
            component={'div'}
          >
            {title}
          </Title>
          <Text fontSize={14}>{HTMLReactParser(text)}</Text>
        </ContentElementQueryList>
      </LinkElementQueryList>
    </WrapperElementQueryList>
  )
}

export default ElementQueryList
