import styled from '@mui/material/styles/styled'
import { LinkAsButton } from '@components/UI/Button'

export const StyledNewsletterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.custom.light4.blue,
}))

export const StyledNewsletterBody = styled('div', {
  name: 'NewsLetter',
  slot: 'Body',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.dark.primary,
  padding: theme.spacing(16, 4, 4, 4),
  fontSize: theme.typography.body2.fontSize,
  lineHeight: 1.43,
  button: {
    height: theme.spacing(12),
    width: 'auto',
  },
}))

export const StyledNewsletterTypography = styled('div', {
  name: 'NewsLetter',
  slot: 'Typography',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.text.dark.primary,
}))

export const StyledNewsletterImageDescription = styled('div', {
  name: 'NewsLetter',
  slot: 'ImageDescription',
})(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: theme.spacing(7.5),
  },
}))

export const StyledNewsletterDescription = styled(StyledNewsletterTypography, {
  name: 'NewsLetter',
  slot: 'Description',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '75%',
  },

  marginBottom: theme.spacing(8),
  p: {
    textAlign: 'center',
  },
}))

export const StyledNewsletterLandingImgsContainer = styled(StyledNewsletterTypography, {
  name: 'NewsLetter',
  slot: 'LandingImagesContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  columnGap: 32,
  textAlign: 'center',
  div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    img: {
      marginTop: theme.spacing(8),
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const DiscountMessageTypography = styled(StyledNewsletterTypography, {
  name: 'DiscountMessage',
  slot: 'Typography',
})(() => ({
  marginBottom: 0,
}))

export const StyledNewsletterTypographyTitle = styled('h1', {
  name: 'NewsLetter',
  slot: 'TypographyTitle',
})(({ theme }) => ({
  fontSize: theme.typography.h1.fontSize,
  fontWeight: 'bold',
  textAlign: 'center',
}))

export const StyledNewsletterTypographySubTitle = styled('div', {
  name: 'NewsLetter',
  slot: 'TypographySubTitle',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  marginBottom: theme.spacing(1),
}))

export const StyledNewsletterTextFieldWrapper = styled('div', {
  name: 'Newsletter',
  slot: 'TextFieldWrapper',
})(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: '100%',
  maxWidth: theme.spacing(122),
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
}))

export const StyledNewsletterPrivacyText = styled('div', {
  name: 'NewsLetter',
  slot: 'PrivacyText',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '75%',
  },
  fontSize: theme.typography.subtitle1.fontSize,
  padding: theme.spacing(4),
  textAlign: 'center',
  a: {
    color: theme.palette.background.dark.secondary,
    fontWeight: 700,
    textDecoration: 'underline',
  },
}))

export const ThankYouContainer = styled('div', {
  name: 'NewsLetterThankYou',
  slot: 'Container',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  columnGap: 32,
  textAlign: 'center',
}))

export const StyledPromoCodeTextField = styled('div', {
  name: 'NewsLetterPromoCode',
  slot: 'TextField',
})(({ theme }) => ({
  padding: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    width: '75%',
  },
  display: 'flex',
  flexDirection: 'row',
  border: `solid 1px ${theme.palette.text.dark.primary}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '5px',
  cursor: 'pointer',
  color: theme.palette.background.dark.secondary,
  textDecoration: 'underline',
  fontWeight: 700,
}))

export const StyledPromoCodeSpan = styled('span', {
  name: 'NewsLetterPromoCode',
  slot: 'Span',
})(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-start',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 600,
  textDecoration: 'underline',
}))

export const StyledLinkAsButton = styled(LinkAsButton, {
  name: 'NewsLetter',
  slot: 'LinkAsButton',
})(({ theme }) => ({
  margin: `${theme.spacing(2)} 0 0 0`,
}))
