import { useContext } from 'react'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import { CmsCarousel } from '@components/Cms/CmsComponents-CSS/CmsCarousel/CmsCarousel'
import { IPlacement, isCMCollection } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import carouselStyles from './styles/carousel.module.scss'
import tileStyles from './styles/tile.module.scss'
import noTileStyles from './styles/notile.module.scss'
import { CMS_MODULES } from '@components/Cms/constants'

type ContextType = {
  index: number
  placement: IPlacement
  teaserIndex: number
}

/**
 * NOTE: This carousel only accepts CMCollection type to render product carousels
 */
export function ProductCollectionCarousel() {
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<ContextType>
  const { viewtype } = data?.placement
  const productsCollection = data?.placement?.items?.find(item => isCMCollection(item)) as ICMCollection
  const slidesPerView = productsCollection?.collectionMaxElementNumber
  // TODO: product image style
  const productImageStyle = productsCollection?.collectionProductStyle

  if (productsCollection?.viewtype !== 'cly-products') return null

  return (
    <CmsCarousel
      products={productsCollection?.teasableItems || []}
      slidesPerView={slidesPerView}
      styleOverride={
        viewtype === CMS_MODULES.CLY_PRODUCTS
          ? [carouselStyles, noTileStyles]
          : [carouselStyles, tileStyles]
      }
    />
  )
}

export default ProductCollectionCarousel
