import React from 'react'
import { CmsCountdown } from '@components/Cms/CmsComponents-CSS/CmsCountdown'
import { CmsModuleIcon } from '@components/Cms/CmsComponents-CSS/CmsModuleIcon/CmsModuleIcon'
import {
  SquareBoardWithSplitArticle,
  SquareBoardWithSplitCTAContainer,
  SquareBoardWithSplitPreTitle,
  SquareBoardWithSplitTextContent,
} from '../SquareBoardWithSplit.style'
import { CmsProductTile } from '../../../../CmsComponents-CSS/CmsProductTile/CmsProductTile'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsTeaserBanner'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'
import { CmsCta } from '@components/Cms/CmsComponents-CSS/CmsCta'
import { IViewType } from '../../../../../../types/cmsPlacement/ViewType'
import { cmsApiService } from '@foundation/apis/cms/cms.ssr.service'
import { CmsTeaserDetailText } from '@components/Cms/CmsComponents-CSS/CmsTeaserDetailText/CmsTeaserDetailText'
import { CmsTeaserTitle } from '@components/Cms/CmsComponents-CSS/CmsTeaserTitle/CmsTeaserTitle'
import { ProductContextProvider } from '@components/PagesSeo/product/context/ProductContext'

export const SquareBoardWithSplitText: React.FC<{
  teaser?: ILXTeaser
  viewType?: IViewType
  bannerIndex?: number
  teaserIndex?: number
}> = ({ teaser, viewType, bannerIndex, teaserIndex }) => {
  const {
    teaserTitle,
    teaserText,
    teaserTitleValue,
    teaserTextValue,
    teaserOverlaySettingsValue,
    teaserOverlayTextAlignValue,
    teaserOverlayStyleValue,
    promoteToH1,
    teaserLXCallToActionSettings,
    teaserPreTitle,
    teaserIcon,
    productData,
    isFrameAdvisor,
  } = useCmsTeaserBanner({ item: teaser, viewType: viewType }) || {}

  const content = (
    <>
      {teaserIcon && <CmsModuleIcon teaserIcon={teaserIcon} />}
      <CmsCountdown teaser={teaser} />
      <>
        {teaserPreTitle && (
          <>
            <SquareBoardWithSplitPreTitle
              data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
              variant={promoteToH1 ? 'h1' : 'h2'}
              fontWeight={'600'}
            >
              {teaserPreTitle}
            </SquareBoardWithSplitPreTitle>
          </>
        )}
        {teaserTitleValue && (
          <CmsTeaserTitle dataCmMetadata={`[{"_":"properties.${teaserTitle}"}]`} variant={promoteToH1 ? 'h1' : 'h2'}>
            {teaserTitleValue}
          </CmsTeaserTitle>
        )}
        {teaserTextValue && (
          <CmsTeaserDetailText
            dataCmMetadata={`[{"_":"properties.${teaserText}"}]`}
            teaserTextValue={teaserTextValue}
          />
        )}
      </>
      <CmsProductTile />
    </>
  )

  return (
    <>
      <SquareBoardWithSplitTextContent>
        <SquareBoardWithSplitArticle
          overlaysettings={teaserOverlaySettingsValue}
          overlaytxtalign={cmsApiService.getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
          teaseroverlaystyle={teaserOverlayStyleValue}
        >
          <ProductContextProvider productData={{ product: productData }}>{content}</ProductContextProvider>
        </SquareBoardWithSplitArticle>
        {!!teaserLXCallToActionSettings && teaserLXCallToActionSettings?.length > 0 && (
          <SquareBoardWithSplitCTAContainer data-element-id={isFrameAdvisor && 'X_X_Footer_FrameGenius_Open'}>
            {teaserLXCallToActionSettings?.map((actionSettings, index) => (
              <CmsCta
                dataElementId={`X_X_${teaserIndex}Placement_Banner${bannerIndex}_CTA${index + 1}`}
                key={`cms-content__text-module--cta-${index + 1}`}
                teaserCtaSetting={actionSettings}
              />
            ))}
          </SquareBoardWithSplitCTAContainer>
        )}
      </SquareBoardWithSplitTextContent>
    </>
  )
}
