import React, { useState } from 'react'
import { IPlacement, isCMArticle, isLXTeaser, isCMTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMArticle } from '@typesApp/cmsPlacement/CMArticle'
import { ICMTeaser } from '@typesApp/cmsPlacement/CMTeaser'
import {
  AccordionDetails,
  ContentFaqs,
  StyledFaqLink,
  StyledAccordion,
  StyledAccordionSummary,
  TitleFaqs,
  WrapperFaqs,
} from './Faqs.style'
import { StyledAnchor } from '../../UI'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { v4 as uuid } from 'uuid'

const Faqs: React.FC<{ placement: IPlacement }> = ({ placement }) => {
  const teaser = placement?.items?.find(isLXTeaser)
  const title = teaser?.teaserTitle1 || ''

  const placementItems = placement?.items
    ?.filter(item => isCMArticle(item) || isCMTeaser(item))
    .map(item => {
      if (isCMArticle(item)) {
        // Set unique id for each article in case it's missing
        // This is critical for the accordion to work properly
        const articleItem = {
          ...item,
          id: item.id ?? uuid(),
        }

        return <AccordionArticle key={articleItem.id} article={articleItem} />
      }

      const teaserItem = item as ICMTeaser

      return (
        <>
          <StyledFaqLink>
            <StyledAnchor
              external
              href={teaserItem.formattedUrl ?? teaserItem.teaserTargets?.[0]?.target.formattedUrl ?? ''}
            >
              {teaserItem.teaserTitle}
            </StyledAnchor>
          </StyledFaqLink>
        </>
      )
    })

  return (
    <WrapperFaqs>
      <TitleFaqs>{title}</TitleFaqs>
      <ContentFaqs>{placementItems}</ContentFaqs>
    </WrapperFaqs>
  )
}

const AccordionArticle: React.FC<{ article: ICMArticle }> = ({ article }) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (id?: string) => (_, isExpanded: boolean) => {
    if (id) {
      setExpanded(isExpanded ? id : false)
    }
  }
  return (
    <StyledAccordion expanded={expanded === article.id} onChange={handleChange(article?.id)}>
      <StyledAccordionSummary
        expandIcon={
          expanded ? (
            <SVGIcon library="global" name="minus-circle" size={32} />
          ) : (
            <SVGIcon library="global" name="plus-circle" size={32} />
          )
        }
      >
        {article.title}
      </StyledAccordionSummary>
      <AccordionDetails dangerouslySetInnerHTML={{ __html: article?.detailText }} />
    </StyledAccordion>
  )
}

export default Faqs
