import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { sendNewsletterSubscriptionEvent } from '@foundation/analytics/tealium/lib'
import { EARLY_ACCESS_PROMO, HTTP_CODE_CONFLICT, HTTP_CODE_OK, USER_SEGMENT_EARLY_ACCESS } from '@constants/common'
import { useSite } from '@foundation/hooks/useSite'
import { NewsletterSubscriptionResponse, useSubscribeNewsletterMutation } from '@features/newsletter/query'
import { NEWSLETTER } from '@foundation/constants/common'
import { isEmpty } from 'lodash-es'
import { WelcomeContainer } from './WelcomeContainer'
import { SuccessContainer } from './SuccessContainer'
import { useLazyGetCampaignAccessQuery } from '@features/customerSegments/query'
import { PERSONALIZATION_ID } from '@foundation/constants/user'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { useCookies, useCustomerSegmentsUtil } from '@utils/Cookies'

export type NewsLetterResultObject = {
  error?: boolean
  text?: string
}

export const NEWS_LETTER_SUBSCRIBE_BANNER_CROP = 'G1_W_2_ITEMS'

export const NewsLetterSubscribe: React.FC = () => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const [result, setResult] = useState<NewsLetterResultObject>({})
  const [triggerGetCampaignAccess] = useLazyGetCampaignAccessQuery()
  const { refetchCookies } = useCookies()
  const hasSubscribed = useCustomerSegmentsUtil().includes(USER_SEGMENT_EARLY_ACCESS)
  const [subscribeNewsletter, { isLoading: isSubscribing }] = useSubscribeNewsletterMutation()
  const personalizationId = localStorageUtil.get(PERSONALIZATION_ID) ?? ''

  const doSubscribe = (email: string) => {
    subscribeNewsletter({
      email,
      newsletter_id: mySite.newsletterType || NEWSLETTER,
      from: 'footer',
    })
      .unwrap()
      .then((result: NewsletterSubscriptionResponse) => {
        handleSubscribeResult(result.code, email)
      })
      .catch(e => {
        const errorKey = e.response?.data?.errors?.[0]?.errorKey
        const genericErrorMessage = t('NewsLetterDrawerContent.Errors.Generic')

        setResult({
          error: true,
          text: t(`error-message.${errorKey}`, genericErrorMessage),
        })
      })
  }

  const handleSubscribeResult = (code: string, email: string) => {
    if (code === HTTP_CODE_OK) {
      setResult({
        error: false,
        text: t('Newsletter.Success'),
      })
      sendNewsletterSubscriptionEvent(email.toLowerCase())
      triggerGetCampaignAccess({
        personalizationId,
        storeId: mySite.storeId,
        campaignName: EARLY_ACCESS_PROMO,
      }).unwrap()
      .finally(() => refetchCookies())
    } else if (code === HTTP_CODE_CONFLICT) {
      setResult({
        error: true,
        text: t('NewsLetterDrawerContent.Errors.Duplicate'),
      })
      sendNewsletterSubscriptionEvent(email.toLowerCase())
    } else {
      setResult({
        error: true,
        text: t('NewsLetterDrawerContent.Errors.Generic'),
      })
    }
  }
  const showWelcome = !hasSubscribed && (isEmpty(result) || result.error)

  return showWelcome ? (
    <WelcomeContainer doSubscribe={doSubscribe} isBusy={isSubscribing} result={result} setResult={setResult} />
  ) : (
    <SuccessContainer />
  )
}
