import React, { useContext } from 'react'
import GenericBanner from '../GenericBanner'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import styles from './styles/FullWidthBanner.module.scss'
import { PlacementContextType } from '../PlacementContextWrapper/types/PlacementContextWrapper.types'
import { CRITICAL_INDEX } from '@components/Cms/constants'

const FullWidthBanner: React.FC = () => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  if (!context) return null
  const { data } = context as ContextWrapperData<PlacementContextType>

  return (
    <GenericBanner
      crop="FULL_WIDTH_BANNER_L"
      className={styles.fullWidthBanner}
      lazy={data.index >= CRITICAL_INDEX}
      termsAndConditionsClassName={styles.fullWidthBannerTermsConditions}
      videoWrapperClassName={styles.fullWidthBannerVideoWrapper}
    />
  )
}

export default FullWidthBanner
