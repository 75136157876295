import React, { useState } from 'react'
import { Pagination } from 'swiper'
import { SwiperProps, SwiperSlide } from 'swiper/react'
import { useMediaQuery, useTheme } from '@mui/material'
// components
import TextModule from '../../CmsModules/TextModule'
import MediaOverlap from '../../CmsModules/MediaOverlap'
import useBreakpoints from '../../../hooks/useBreakpoints'
// types
import { ModulesProps } from '@typesApp/cms'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { applyCarousel, boardsCarouselGap, isDescriptionOnly } from '@utils/boardsWithFields'
// styles
import { BoardWithFieldsContainer, BoardWithFieldsItem, BoardWithFieldsSlider } from './BoardWithFields.style'
import { LAZY_LOAD_THRESHOLD } from '@constants/cms'

export interface BoardWithFieldsModuleProps extends ModulesProps {
  placement: IPlacement | ICMCollection
}

const BoardWithFieldsModule: React.FC<BoardWithFieldsModuleProps> = ({
  placement,
  placementCenter,
  index: placementIndex,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const { isDesktop, isMobile } = useBreakpoints()
  const theme = useTheme()
  const is768AndBelow = useMediaQuery(theme.breakpoints.down(769))

  let teasers = 'items' in placement ? placement.items.filter(isLXTeaser) : placement.teasableItems.filter(isLXTeaser)
  const viewType = placement?.viewtype || 'default'
  const carouselTeasersCount = !isDesktop ? 3 : 4
  const crop = `G1_W_${teasers.length > 4 ? 4 : teasers.length}_ITEMS`
  const teaserCountBreakpoint =
    (teasers.length === 4 && isDescriptionOnly(teasers)) || teasers.length === 3 ? is768AndBelow : isDesktop
  const isWithCarousel =
    teasers.length >= carouselTeasersCount && applyCarousel(teasers, placement.viewtype, teaserCountBreakpoint)
  const isCarouselFourAbove = viewType === 'boards-with-fields-below' && teasers.length > 3 && !is768AndBelow
  const isLastItem = activeIndex === teasers.length - 1 || (isCarouselFourAbove && activeIndex === teasers.length - 3)
  const sliderProps: SwiperProps = {
    spaceBetween: boardsCarouselGap(isWithCarousel, isMobile),
    slidesPerView: 'auto',
    roundLengths: true,
    modules: [Pagination],
    navigation: true,
    pagination: {
      modifierClass: 'custom-',
      progressbarFillClass: 'custom-progressbar-fill',
      type: 'bullets',
      clickable: true,
    },
  }

  const generateID = (index: number) => {
    if (activeIndex === index) return 'activeSlide'
    else return 'inActiveSlide'
  }

  return (
    <BoardWithFieldsContainer isWithCarousel={isWithCarousel}>
      {isWithCarousel ? (
        <BoardWithFieldsSlider
          {...sliderProps}
          centeredSlides={false}
          onActiveIndexChange={i => setActiveIndex(i.activeIndex)}
          watchOverflowLastItem={isLastItem}
        >
          {teasers.map((teaser, index) => {
            const newTeaser = { ...teaser, placementCounter: { placementIndex, tileIndex: index } }
            return (
              <SwiperSlide key={`slide__${index}`} id={generateID(index)}>
                <BoardWithFieldsItem isWithCarousel={isWithCarousel}>
                  <MediaOverlap
                    crop={crop}
                    teaser={newTeaser}
                    teaserIndex={index}
                    viewType={placement.viewtype}
                    isLazy={index >= LAZY_LOAD_THRESHOLD}
                  />
                  <TextModule
                    teaserIndex={index}
                    teaser={newTeaser}
                    viewType={placement.viewtype}
                    placementCenter={placementCenter}
                  />
                </BoardWithFieldsItem>
              </SwiperSlide>
            )
          })}
        </BoardWithFieldsSlider>
      ) : (
        teasers.map((teaser, index) => {
          const newTeaser = { ...teaser, placementCounter: { placementIndex } }
          return (
            <BoardWithFieldsItem key={`board__${index}`}>
              <MediaOverlap
                crop={crop}
                teaser={newTeaser}
                teaserIndex={index}
                viewType={placement.viewtype}
                isLazy={index >= LAZY_LOAD_THRESHOLD}
              />
              <TextModule
                teaserIndex={index}
                teaser={newTeaser}
                viewType={placement.viewtype}
                placementCenter={placementCenter}
                sectionTitle={false}
              />
            </BoardWithFieldsItem>
          )
        })
      )}
    </BoardWithFieldsContainer>
  )
}

export default BoardWithFieldsModule
