import React from 'react'
import { useTranslation } from 'next-i18next'

import {
  StyledNewsletterBody,
  StyledNewsletterContainer,
  StyledNewsletterPrivacyText,
  StyledNewsletterTypographyTitle,
  ThankYouContainer,
  StyledNewsletterLandingImgsContainer,
  StyledNewsletterTypographySubTitle,
  StyledNewsletterImageDescription,
} from './NewsLetterSubscribe.style'
import { useNewsletterSubscriptionSuccess } from '@foundation/hooks/useNewsletterSubscriptionSuccess'
import { useSelector } from 'react-redux'
import { newsletterPromoCodeSelector } from '@redux/selectors/site'
import { PromoCodeWithTooltip } from '@layouts/Footer/components/NewsLetter/PromoCodeWithTooltip'
import { CmsCta } from '@components/Cms/CmsComponents-CSS/CmsCta'
import MediaOverlap from '@components/CmsModules/MediaOverlap'
import { NEWS_LETTER_SUBSCRIBE_BANNER_CROP } from './NewsLetterSubscribe'
import { isEarlyAccesFeatureEnabled } from '@utils/common'
import { useSearchParams } from 'next/navigation'
import { PROMO_URL_PARAM } from '@constants/common'

export const SuccessContainer: React.FC = () => {
  const { t } = useTranslation()
  const newsletterPromoCode = useSelector(newsletterPromoCodeSelector)
  const { signUpSuccessTitle, signUpSuccessSubtitle, promoCodeFields, landingPageSuccessMedia, privacyText } =
    useNewsletterSubscriptionSuccess()
  const isEarlyAccessEnabled = isEarlyAccesFeatureEnabled()
  const searchParams = useSearchParams()
  const promo = searchParams.get(PROMO_URL_PARAM)
  const isPromo = isEarlyAccessEnabled && !!promo
  const glassesPages = ['glasses', 'lunettes']
  return (
    <StyledNewsletterContainer>
      <div>
        <StyledNewsletterBody>
          <ThankYouContainer>
            <StyledNewsletterTypographyTitle>{signUpSuccessTitle}</StyledNewsletterTypographyTitle>
            <StyledNewsletterTypographySubTitle dangerouslySetInnerHTML={{ __html: signUpSuccessSubtitle }} />

            {newsletterPromoCode && (
              <>
                <PromoCodeWithTooltip translate={t} promoCodeFields={promoCodeFields} />
              </>
            )}
            <StyledNewsletterLandingImgsContainer>
              {landingPageSuccessMedia?.map((item, index) => {
                return (
                  <div key={`success_media_container_${index}`}>
                    <MediaOverlap
                      crop={NEWS_LETTER_SUBSCRIBE_BANNER_CROP}
                      teaser={item}
                      teaserIndex={index}
                      viewType={'boards-with-fields-below'}
                    />
                    <StyledNewsletterImageDescription
                      dangerouslySetInnerHTML={{
                        __html: item.teaserText2 || '',
                      }}
                    />
                    <div>
                      {item.teaserLXCallToActionSettings && (
                        <CmsCta
                          dataDescription={item.teaserLXCallToActionSettings[0].callToActionText}
                          dataElementId={`${isPromo ? 'EarlyAccess' : 'Subscribe'}_${
                            glassesPages.includes(
                              item.teaserLXCallToActionSettings[0].target.formattedUrl.split('/')?.[2]
                            )
                              ? 'ShopGlasses'
                              : 'ShopCL'
                          }`}
                          teaserCtaSetting={item.teaserLXCallToActionSettings[0]}
                        />
                      )}
                    </div>
                  </div>
                )
              })}
            </StyledNewsletterLandingImgsContainer>
          </ThankYouContainer>
        </StyledNewsletterBody>
      </div>
      <StyledNewsletterPrivacyText
        dangerouslySetInnerHTML={{
          __html: privacyText ?? '',
        }}
      />
    </StyledNewsletterContainer>
  )
}
