import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import {
  StyledNewsletterBody,
  StyledNewsletterContainer,
  StyledNewsletterPrivacyText,
  StyledNewsletterTextFieldWrapper,
  StyledNewsletterTypographyTitle,
  StyledNewsletterDescription,
  StyledNewsletterLandingImgsContainer,
  StyledNewsletterImageDescription,
} from './NewsLetterSubscribe.style'
import { useNewsletterSubscription } from '@foundation/hooks/useNewsletterSubscription'
import addressUtil from '@utils/addressUtil'
import useBreakpoints from '@hooks/useBreakpoints'
import { TextField } from '@components/UI'
import { isEmpty } from 'lodash-es'
import { SubscribeButton } from '@views/ProductDetails/components/ProductAvailableNotificationDrawer/ProductAvailableNotificationDrawer.style'
import { NEWS_LETTER_SUBSCRIBE_BANNER_CROP, NewsLetterResultObject } from './NewsLetterSubscribe'
import MediaOverlap from '@components/CmsModules/MediaOverlap'
import { BoardWithFieldsItem } from '@components/CmsPlacement/BoardWithFields/BoardWithFields.style'

type WelcomeProps = {
  doSubscribe: (email: string) => void
  result: NewsLetterResultObject
  setResult: (ResultObject) => void
  isBusy?: boolean
}

export const WelcomeContainer: React.FC<WelcomeProps> = ({ doSubscribe, result, setResult, isBusy }) => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)

  const [email, setEmail] = useState('')

  const {
    title,
    description,
    isTextFieldExists,
    emailLabel,
    privacyText,
    isSubscribeButtonExists,
    subscribeCtaText,
    landingPageMedia,
  } = useNewsletterSubscription()

  const { isMobile } = useBreakpoints()

  const onChange = e => {
    setResult({})
    setEmail(e.target.value)
    setError(!addressUtil.validateEmail(e.target.value))
  }

  const subscribe = async () => {
    if (addressUtil.validateEmail(email)) {
      doSubscribe(email)
    } else {
      setError(true)
    }
  }

  return (
    <StyledNewsletterContainer>
      <div>
        <StyledNewsletterBody>
          <StyledNewsletterTypographyTitle>{title}</StyledNewsletterTypographyTitle>
          <StyledNewsletterDescription dangerouslySetInnerHTML={{ __html: description }} />
          {isTextFieldExists && (
            <StyledNewsletterTextFieldWrapper>
              <TextField
                error={error || result.error}
                helperText={
                  error && isEmpty(result)
                    ? t('NewsLetterDrawerContent.Errors.Input')
                    : result.error
                      ? result.text
                      : null
                }
                variant="outlined"
                margin="normal"
                fullWidth
                name="email"
                label={emailLabel}
                type="email"
                id="email"
                value={email}
                required
                showvalidationstatus={true}
                onChange={onChange}
              />
            </StyledNewsletterTextFieldWrapper>
          )}
          {isSubscribeButtonExists && (
            <SubscribeButton disabled={!email || error || isBusy} onClick={subscribe} fullwidth>
              {subscribeCtaText}
            </SubscribeButton>
          )}
          <StyledNewsletterLandingImgsContainer>
            {landingPageMedia?.map((item, index) => {
              return (
                <div key={`landing_media_container_${index}`}>
                  <BoardWithFieldsItem key={`board__${index}`}>
                    <MediaOverlap
                      crop={NEWS_LETTER_SUBSCRIBE_BANNER_CROP}
                      teaser={item}
                      teaserIndex={index}
                      viewType={'boards-with-fields-below'}
                    />
                  </BoardWithFieldsItem>
                  <StyledNewsletterImageDescription
                    dangerouslySetInnerHTML={{
                      __html: item.teaserText2 || '',
                    }}
                  />
                </div>
              )
            })}
          </StyledNewsletterLandingImgsContainer>

          <StyledNewsletterPrivacyText
            dangerouslySetInnerHTML={{
              __html: privacyText,
            }}
          />
        </StyledNewsletterBody>
      </div>
    </StyledNewsletterContainer>
  )
}
