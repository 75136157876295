import React, { useContext } from 'react'

import GenericBanner from '../GenericBanner'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import { PlacementContextType } from '../PlacementContextWrapper/types/PlacementContextWrapper.types'
import { CRITICAL_INDEX } from '@components/Cms/constants'

import styles from './styles/LandscapeBanner.module.scss'

const LandscapeBanner: React.FC = () => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  if (!context) return null
  const { data } = context as ContextWrapperData<PlacementContextType>

  return (
    <GenericBanner
      crop="LANDSCAPE_BANNER_M"
      className={styles.landscapeBanner}
      lazy={data?.index >= CRITICAL_INDEX}
      termsAndConditionsClassName={styles.landscapeBannerTermsConditions}
      videoWrapperClassName={styles.landscapeBannerVideoWrapper}
    />
  )
}

export default LandscapeBanner
